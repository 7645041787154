import React, { useRef, useState, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { SignatureInputContainer, SignatureCorners } from './SignatureInput.style'
import Text from '../Text'
import { Row, Col } from '../grid'

function SignatureInput({ value, onChange }) {
  const input = useRef()

  useEffect(() => {
    if (value) { input.current.fromDataURL(value) }
  }, [])
 
  return <>
    <Row padBottom="16px">
      <Col alignHorizontal="flex-start"><Text size={13} bold>Sign in the grey area</Text></Col>
      <Col alignHorizontal="flex-end"><Text size={13} clickable onClick={() => { value && input.current.clear(); onChange(null) }} color={value ? "text" : "grey3"} >Clear</Text></Col>
    </Row>
    
    <SignatureInputContainer>
      <SignatureCorners />
      <SignatureCanvas ref={input} canvasProps={{ className: "sigPad" }} onEnd={() => onChange && onChange(input.current.toDataURL('image/png'))}/>
    </SignatureInputContainer>
  </>
}

export default SignatureInput
