import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { IMeerkatWebTheme } from '../../../theme.style'

const CircularProgressContainer = styled.div<{ white?: boolean; small?: boolean }, IMeerkatWebTheme>`
  .MuiCircularProgress-colorPrimary {
    color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)} !important;

    ${(props) =>
      props.small &&
      css`
        width: 20px !important;
        height: 20px !important;
      `}
  }
`

export { CircularProgressContainer }
