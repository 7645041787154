import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { ApolloProvider } from 'react-apollo'
import client from './apollo/client'
import theme from './theme.style'
import createStore from './store'
import { Provider } from 'react-redux'
import { FormRenderProvider } from 'react-form-helper'
import { renderInput } from './components/generic/form'
import { ChatProvider } from './context'              
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const store = createStore()

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <FormRenderProvider renderInput={renderInput}>
          <ApolloProvider client={client}>
            <ChatProvider>      
              {element}
            </ChatProvider>
          </ApolloProvider> 
        </FormRenderProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
)
