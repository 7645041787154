/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import i18n from "./src/i18n"
import 'babel-polyfill'
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/messaging';
import firebase from 'gatsby-plugin-firebase';

console.log("i18n language", i18n && i18n.language)

firebase.analytics();
firebase.performance();

// You can delete this file if you're not using it
export { wrapRootElement } from './src/wrap-root-element'
