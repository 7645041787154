import React from "react";
import TextInput from "../../generic/TextInput";
import ImageGridSelect from "../../generic/ImageGridSelect";
import Select from "../../generic/Select";
import Checkbox from "../../generic/Checkbox";
import Selector from "../../generic/Selector";
import DatePicker from "../../generic/DatePicker";
import Button from "../../generic/Button";
import { Row, Col } from "../../generic/grid";
import OptionSelector from "../OptionSelector";
import FileUpload from "../FileUpload";
import SelectableItemRadioGroup from "../SelectableRadioButtonGroup";
import Slider from "../Slider";
import SignatureInput from "../SignatureInput";
import Autocomplete from "../Autocomplete";
import { FieldValues } from 'react-form-helper';
import WhatsappCheckbox from "../../generic/WhatsappCheckbox";

const YesNo = (props) => {
  if (props.column) {
    return (
      <Col centerHorizontal>
        <Button onClick={() => props.input.onChange("Y")} title="Yes" />
        <div style={{ height: "7px" }} />
        <Button onClick={() => props.input.onChange("N")} title="No" />
      </Col>
    );
  }

  return (
    <Row fill centerHorizontal>
      <Button onClick={() => props.input.onChange("Y")} title="Yes" />
      <div style={{ width: "14px" }} />
      <Button onClick={() => props.input.onChange("N")} title="No" />
    </Row>
  );
};

const ButtonSelectOptions = ({ field, input }) => {
  return (
    <Row wrap alignVertical="center" style={{ margin: "auto", borderWidth: 1, borderColor: "blue" }}>
      {field.options.map((option, i) => (
        <Button key={i} style={{ margin: "8px" }} onClick={() => input.onChange(option.value || option)}>
          {option.label || option}
        </Button>
      ))}
    </Row>
  );
};

const renderInput = (type) => {
  const renderer = ({ required, input, field, disabled, meta }) => {
    if (type === "options") {
      return <ButtonSelectOptions field={field} input={input} />;
    }
    if (type === "yesno") {
      return <YesNo column={field.column} input={input} />;
    }
    if (type === "select") {
      return (
        <Select
          required={required || field.required}
          label={field.label}
          {...input}
          options={field.options}
          error={meta.touched && !!meta.error}
          disabled={disabled || field.disabled}
          helperText={meta.touched && meta.error}
        />
      );
    }
    if (type === "selector") {
      return (
        <Selector
          label={field.label}
          required={required || field.required}
          {...input}
          options={field.options}
          error={meta.touched && !!meta.error}
          disabled={disabled || field.disabled}
          helperText={meta.touched && meta.error}
        />
      );
    }
    if (type === "autocomplete") {
      return (
        <Autocomplete
          required={required || field.required}
          label={field.label}
          {...input}
          options={field.options}
          error={meta.touched && !!meta.error}
          disabled={disabled || field.disabled}
          helperText={meta.touched && meta.error}
        />
      );
    }
    if (type === "checkbox") {
      return <Checkbox label={field.label} {...input} />;
    }
    if (type === "whatsappOpt") {
      return <FieldValues names={[field.cellNumberField || "cellNumber"]} renderValues={({ values }) => {
        return <WhatsappCheckbox
          {...input}
          cellNumber={values[field.cellNumberField || "cellNumber"] || ""}
          required={required || field.required}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />;
      }} />

    }
    if (type === "date") {
      return (
        <DatePicker
          required={required || field.required}
          label={field.label}
          {...input}
          error={meta.touched && !!meta.error}
          disabled={disabled || field.disabled}
          helperText={meta.touched && meta.error}
        />
      );
    }
    if (type === "optionselector") {
      return <OptionSelector value={input.value} onChange={input.onChange} options={field.options} />;
    }
    if (type === "fileupload") {
      return (
        <FileUpload
          onChange={input.onChange}
          value={input.value}
          prompt={field.prompt}
          accept={field.fileTypes || "*"}
          capture={field.capture}
        />
      );
    }
    if (type === "radiogroup") {
      return (
        <SelectableItemRadioGroup
          label={field.label}
          required={required || field.required}
          options={field.options}
          value={input.value}
          onChange={input.onChange}
          multiple={field.multiple}
          includeOther={field.includeOther}
          otherField={field.otherField}
          meta={meta}
          onBlur={input.onBlur}
        />
      );
    }
    if (type === "slider") {
      return (
        <Slider
          label={field.label}
          required={required || field.required}
          minValue={field.min}
          maxValue={field.max}
          step={field.step}
          value={input.value}
          onChange={input.onChange}
          startText={field.startText}
          endText={field.endText}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      );
    }
    if (type === "imagegridselect") {
      return (
        <ImageGridSelect
          required={field && field.required}
          label={field.label}
          value={input.value}
          onChange={input.onChange}
          options={field.options}
          error={meta.touched && !!meta.error}
          disabled={disabled}
          helperText={meta.touched && meta.error}
        />
      );
    }
    if (type === "signature") {
      return (
        <SignatureInput
          label={field.label}
          value={input.value}
          onChange={input.onChange}
          error={meta.touched && !!meta.error}
          disabled={disabled}
          helperText={meta.touched && meta.error}
        />
      );
    }
    return (
      <TextInput
        required={required || (field && field.required)}
        error={meta.touched && !!meta.error}
        label={field && field.label}
        {...input}
        disabled={disabled || field.disabled}
        helperText={meta.touched && meta.error}
      />
    );
  };

  return renderer;
};

export default renderInput;
