import React from "react";

const renderLabel = () => {
  const renderer = () => {
    return <div />;
  };

  return renderer;
};

export default renderLabel;
