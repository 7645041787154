import React from 'react'
// import {} from './Autocomplete.style'
import TextField from '../TextInput';
import Autocomplete from '@material-ui/lab/Autocomplete';

function MyAutocomplete({ options, onChange, value, ...props }) {
  return (
    <Autocomplete
      disablePortal
      freeSolo
      value={value}
      onChange={(e, v) => onChange(v)}
      options={options}
      blurOnSelect
      autoSelect
      renderInput={(params) => <TextField {...params} {...props} />}
    />
  );
}

export default MyAutocomplete
