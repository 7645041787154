import openMenu from './menu.svg'
import close from './close.svg'
import whiteClose from './whiteClose.svg'
import logo from './logo.svg'
import rmaLogo from './rma.png'
import profile from './profile.svg'
import addBucket from './addBucket.svg'
import email from './email.svg'
import youTube from './youTube.svg'
import tickBox from './tickBox.svg'
import backArrow from './backArrow.svg'
import mokuPopup from './mokuPopup.svg'
import mokuHead from './mokuHead.svg'
import whatsappIcon from './whatsappIcon.png'
import downArrow from './downArrow.svg'
import closeCircle from './closeCircle.svg'
import mokuChat from './mokuChat.svg'
import mokuCircle from './mokuCircle.svg'
import closeCircleSolid from './closeCircleSolid.svg'
import largeBlob from './largeBlob.svg'
import mokuSmall from './mokuSmall.png'
import chevronRight from './chevronRight.svg'
import logout from './logout.svg'
import logoutBlack from './logoutBlack.svg'
import sittingOnMoney from './sittingOnMoney.svg'
import announcement from './announcement.svg'
import rollingMoney from './rollingMoney.svg'
import trash from './trash.svg'
import greenTickFaded from './greenTickFaded.svg'
import redCrossFaded from './redCrossFaded.svg'

const social = {
  email,
  youTube
}

export {
  openMenu,
  close,
  whiteClose,
  logo,
  profile,
  addBucket,
  social,
  tickBox,
  backArrow,
  mokuPopup,
  mokuHead,
  downArrow,
  whatsappIcon,
  closeCircle,
  mokuChat,
  mokuCircle,
  closeCircleSolid,
  largeBlob,
  mokuSmall,
  chevronRight,
  logout,
  sittingOnMoney,
  announcement,
  rollingMoney,
  trash,
  logoutBlack,
  rmaLogo,
  greenTickFaded,
  redCrossFaded
}
