import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { getPaddingFromProps } from '../../../../lib/styles'
import { PaddingProps } from '../../../../lib/styles/paddingHelper'

interface Props extends PaddingProps {
  grow?: boolean
  size?: number
  centerVertical?: boolean
  alignVertical?: string
  centerHorizontal?: boolean
  alignHorizontal?: string
}

const Col = styled.div<Props>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.grow
      ? css`
          flex-grow: 1;
        `
      : props.size === 0
      ? ``
      : css`
          flex: ${props.size || 1};
        `};

  ${(props) =>
    props.centerVertical &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.alignVertical &&
    css`
      justify-content: ${props.alignVertical};
    `}

  ${(props) =>
    props.centerHorizontal &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.alignHorizontal &&
    css`
      align-items: ${props.alignHorizontal};
    `}

  ${(props) => getPaddingFromProps(props)};
`

export default Col
