import React from 'react'
import { ButtonContainer, SmallButtonContainer } from './Button.style'
import ActivityIndicator from '../ActivityIndicator'
import Text from '../Text'

interface Props {
  inverted?: boolean
  onClick?: () => void
  onDisabledClick?: () => void
  disabled?: boolean
  white?: boolean
  submitting?: boolean
  title?: string
  className?: string
  style?: React.CSSProperties
  noBorder?: boolean
  fullWidth?: boolean
  textSize?: number
  small?: boolean
  square?: boolean
}

export const Button: React.FC<Props> = ({
  children,
  inverted,
  onClick,
  onDisabledClick,
  disabled,
  white,
  submitting,
  title,
  className,
  style,
  noBorder,
  fullWidth,
  textSize,
  small,
  square,
}) => {
  const defaultTextSize = small ? 12 : 16
  const textSizeProp = textSize ? textSize : defaultTextSize
  const Container = small ? SmallButtonContainer : ButtonContainer

  return (
    <Container
      className={className}
      style={style}
      inverted={inverted}
      white={white}
      disabled={disabled}
      allowClick={disabled && !!onDisabledClick}
      onClick={!submitting ? (disabled ? onDisabledClick : onClick) : undefined}
      noBorder={noBorder}
      fullWidth={fullWidth}
      square={square}
    >
      {submitting ? (
        <ActivityIndicator small white={!inverted} />
      ) : (
        <Text
          size={textSizeProp}
          primary
          white={(inverted && white && !disabled) || (!inverted && !white && !disabled)}
        >
          {children || title}
        </Text>
      )}
    </Container>
  )
}

export default Button
