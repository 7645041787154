import fetch from 'isomorphic-fetch'
import { getAccessToken, getAuthAud } from "../services/auth"
import { API_URL, APP_VERSION, API_AUTH_ID } from '../config'
import introspectionQueryResultData from './fragmentTypes.json'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = createUploadLink({
  uri: `${API_URL}graphql`,
});

const middlewareLink = setContext(() => ({
  headers: {
    Authorization: `JWT ${getAccessToken()}`,
    "X-App-Client": API_AUTH_ID,
    "X-App-Version":  APP_VERSION,
    "X-App-Device": getAuthAud()
  }
}));

// use with apollo-client
const httpLinkWithAuth = middlewareLink.concat(httpLink);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const client = new ApolloClient({
  fetch,
  link: httpLinkWithAuth,
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;