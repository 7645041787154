import React from "react";
import renderInput from "./renderInput";

const renderDisplay = (type) => {
  const renderer = ({ input, ...props }) => {
    // console.log('Render Display type',type)
    // console.log('Render Display props',props)
    // console.log('Render Display input',input)
    const Input = renderInput(type);
    return <Input input={input} value={props.value || input.value} {...props} disabled />;
  };

  return renderer;
};

export default renderDisplay;
