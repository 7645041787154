import styled from '@emotion/styled'

const UploadedFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  border-top: 2px solid #F9F8F8;
  border-bottom: 2px solid #F9F8F8;
  margin-top: 8px;
  padding-top: 14px;
  padding-bottom: 14px;
  height: 50px;
`

const DeleteIcon = styled.img`
  margin: 0;
  position: absolute;
  right: 0;
  top: 13px;
`

export {
  UploadedFileContainer,
  DeleteIcon
}
