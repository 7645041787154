import React from 'react'
import ImageGridSelectItem from '../ImageGridSelectItem/ImageGridSelectItem'
import { ImageGridSelectContainer } from './ImageGridSelect.style'

function ImageGridSelect({ value, onChange, options }) {
  return <ImageGridSelectContainer>
    {options.map((option, i) => <ImageGridSelectItem key={i} label={option.label} selected={value && value === option.value} image={value && value === option.value ? option.selectedImage : option.image} onClick={() => onChange(option.value)}/>)}
  </ImageGridSelectContainer>
}

export default ImageGridSelect
