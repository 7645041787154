import React from 'react'
import { FormField } from 'react-form-helper'
import { LayoutContainer, FieldContainer } from './ColumnLayout.style'

function ColumnLayout({ fields }) {
  return <LayoutContainer>
    {Object.keys(fields).map((fieldName, i) => <FieldContainer key={i}><FormField name={fieldName} /></FieldContainer>)}
  </LayoutContainer>
}

export default ColumnLayout