function fbTrack(a,b) {
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) { // eslint-disable-line no-undef
    fbq(a, b); // eslint-disable-line no-undef
  }
}

function gaTrack(...args) {
  if (process.env.NODE_ENV === `production` && typeof window.gtag === `function`) { // eslint-disable-line no-undef
    window.gtag(...args); // eslint-disable-line no-undef
  }
}

function hjIdentify(...args) {
  if (process.env.NODE_ENV === `production` && typeof window.hj === `function`) { // eslint-disable-line no-undef
    window.hj('identify', ...args); // eslint-disable-line no-undef
  }
}


export {
  fbTrack,
  gaTrack,
  hjIdentify
}