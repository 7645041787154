import React from 'react'
import TextField from '@material-ui/core/TextField'
import { TextFieldContainer } from './TextInput.style'
import { InputAdornment } from '@material-ui/core'
import Text from '../Text'

function TextInput({ label, required, ...props }) {
  return <TextFieldContainer>
    <Text size={13} padBottom="6px">{label} {required && <Text size={20} span primary>*</Text>}</Text>
    <TextField label="" variant="outlined" {...props} />
  </TextFieldContainer> 
}


function TextInputCurrency({ label, ...props }) {
  return <TextFieldContainer>
    <TextField 
      label={label} 
      variant="outlined" 
      InputProps={{
        startAdornment: <InputAdornment position="start">R</InputAdornment>,
      }}
      {...props} />
  </TextFieldContainer> 
}

export {
  TextInputCurrency
};

export default TextInput