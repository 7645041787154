import styled from '@emotion/styled'

interface ISelectableItemContainer {
  selected?: boolean
  outlined?: boolean
  leftIndicatorBarColor?: string
  theme: any
}

const SelectableItemContainer = styled.div<ISelectableItemContainer>`
  display: flex;
  flex-direction: row;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.primary + '19'
      : props.outlined
      ? props.theme.colors.white
      : props.theme.colors.grey};
  border: ${(props) => (props.outlined ? `1px solid ${props.theme.colors.grey5}` : 'none')};
  padding: 10px 11px 10px 8px;
  align-items: center;
  border-radius: 4px;
  margin: 4px 0px;

  ${(props) =>
    props.leftIndicatorBarColor && `border-left: 8px solid ${props.theme.colors[props.leftIndicatorBarColor]}`}
`

const SelectableItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
  margin-right: 14px;
`

export { SelectableItemContainer, SelectableItemTitleContainer }
