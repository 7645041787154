import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { device, IMeerkatWebTheme } from '../../../theme.style'

interface Props {
  fullWidth?: boolean
  inverted?: boolean
  white?: boolean
  disabled?: boolean
  noBorder?: boolean
  submitting?: boolean
  allowClick?: boolean
  square?: boolean
}

const ButtonContainer = styled.div<Props, IMeerkatWebTheme>`
  text-align:center;
  min-width: 250px;
  ${(props) =>
    props.fullWidth
      ? css`
          width: calc(100% - 74px);

          ${device.tablet`
      width: calc(100% - 44px);
    `}
        `
      : css`
          width: fit-content;
        `};
  height: ${(props) => (props.square ? '80px' : '52px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  ${(props) =>
    !props.inverted &&
    css`
      background: ${props.theme.colors.primary};
    `}
  ${(props) =>
    props.white &&
    !props.inverted &&
    css`
      background: white;
    `}
  
  ${(props) =>
    !props.disabled &&
    !props.noBorder &&
    css`
      border: 1px solid ${props.white ? 'white' : props.theme.colors.primary};
    `};
  
  ${(props) =>
    !props.square &&
    css`
      border-radius: 50px;
    `}

  padding-left: 34px;
  padding-right: 34px;

  color: ${(props) =>
    (props.inverted && !props.white) || (props.white && !props.inverted) ? props.theme.colors.primary : 'white'};

  ${(props) =>
    props.disabled &&
    !props.submitting &&
    css`
      background-color: ${props.theme.colors.primary}25;
      ${props.allowClick ? '' : 'pointer-events:none;'};
      color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    !props.disabled &&
    !props.submitting &&
    css`
      :hover {
        filter: brightness(120%);
      }
    `}

  ${device.tablet`
    height: 44px;
    padding-left: 19px;
    padding-right: 19px;
    #min-width: 250px;
  `}
}
`

const SmallButtonContainer = styled(ButtonContainer)`
  height: 28px !important;
  min-width: 96px;
  font-size: 12px;
  padding: 0px;
`

export { ButtonContainer, SmallButtonContainer }
