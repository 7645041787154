import React, { useState } from 'react'
import { renderInput } from '../form'
import SelectableItem from '../SelectableItem'
import Text from '../Text'

function SelectableRadioButtonGroup({ label, required, options, value, onChange, onBlur, multiple, includeOther, otherField, meta }) {
  const [otherSelected, setOtherSelected] = useState(false)

  const isSelected = (option) => {
    if (otherSelected) {
      return false
    }
    if (multiple) {
      return value && value.includes(option instanceof Object ? option.value : option)
    } 
    return (option instanceof Object ? option.value : option) === value
  }

  const getNewValue = (option) => {
    if (multiple) {
      const checkValue = option instanceof Object ? option.value : option
      if (value && value.includes(checkValue)) {
        return value.filter(v => v !== checkValue)
      }
      return [...(value || []), checkValue]
    }
    return option instanceof Object ? option.value : option
  }

  console.log("LABEL=", label)

  const { type, label: otherLabel } = otherField || {}
  console.log("OTHER OPTIONS=", otherField)
  const OtherEditField = renderInput(type)

  return <div>
    <Text>{label} {required && <Text span primary size={20}>*</Text>}</Text>
    {options && options.map((option, i) => <SelectableItem key={i} title={option instanceof Object ? option.label : option} control="radio" selected={isSelected(option)} onChange={() => { setOtherSelected(false); onChange(getNewValue(option)); onBlur(getNewValue(option)) }} />)}
    {includeOther && <SelectableItem title="Other" control="radio" selected={otherSelected} onChange={() => { setOtherSelected(true); }} />}
    {otherSelected && <>
      <div style={{ height: "4px" }} />
      <OtherEditField label={otherLabel} meta={meta} field={otherField || {}} input={{ value, onChange, onBlur }} />
    </>}
    {meta.touched && meta.error && <Text red>{meta.error}</Text>}
  </div>
}

export default SelectableRadioButtonGroup
