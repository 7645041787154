import styled from '@emotion/styled'

const SignatureInputContainer = styled.div`
  position: relative;
  width: 328px;
  height: 254px;
  left: 16px;

  background: ${props => props.theme.colors.grey};
  border-radius: 4px;

  .sigPad {
    position: relative;
    width: 100%;
    height: 100%;
  }

  :before {
    position: absolute;
    top:0;
    left:0;
    border-color: ${props => props.theme.colors.primary};
    border-width: 2px 0 0 2px;
    border-style: solid;
    width: 17px;
    height: 17px;
    content: ' ';
  }

  :after {
    position: absolute;
    top:0;
    right:0;
    border-color: ${props => props.theme.colors.primary};
    border-width: 2px 2px 0 0;
    border-style: solid;
    width: 17px;
    height: 17px;
    content: ' ';
  }
`

const SignatureCorners = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';

  :before {
    position: absolute;
    bottom:0;
    right:0;
    border-color: ${props => props.theme.colors.primary};
    border-width: 0 2px 2px 0;
    border-style: solid;
    width: 17px;
    height: 17px;
    content: ' ';
  }

  :after {
    position: absolute;
    bottom:0;
    left:0;
    border-color: ${props => props.theme.colors.primary};
    border-width: 0 0 2px 2px;
    border-style: solid;
    width: 17px;
    height: 17px;
    content: ' ';
  }
`

export {
  SignatureInputContainer,
  SignatureCorners
}
