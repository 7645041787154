import React from "react";
import TextInput from "../TextInput";
import { StyledMenuItem, StyledMenuItemText } from "./Select.style";

const getLabelValue = (option) => {
  if (option instanceof Object) {
    return { label: option.label || option.value, caption: option.caption, value: option.value };
  }
  return { label: option, value: option };
};

function Select({ label, value, options, ...props }) {
  return (
    <TextInput
      label={label}
      value={value}
      select
      {...props}
      SelectProps={{
        renderValue: (value) => {
          const option = options.find((o) => o.value === value);

          if (option) {
            return option.label;
          }

          return value
        },
      }}
    >
      {options &&
        options.map((option, i) => {
          const { label, value, caption } = getLabelValue(option);
          return (
            <StyledMenuItem key={i} value={value}>
              <StyledMenuItemText primary={label} secondary={caption} />
            </StyledMenuItem>
          );
        })}
    </TextInput>
  );
}

export default Select;
