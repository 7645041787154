import styled from '@emotion/styled'

const RedText = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 20px;
  position: relative; 
  top: 3px;
  font-weight: 900;
`

export {
  RedText
}