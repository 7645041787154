import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox';
import { WhatsAppCheckboxContainer, WhatsAppIconContainer, Row, WhatsAppIcon, WhatsAppOptInCheckboxContainer, WhatsAppOptInTextContainer, UseAnotherNumberTextContainer } from './WhatsappCheckbox.style'
import TextInput from '../TextInput';
import { whatsappIcon } from '../../../images'

function whatsappCheckbox({ value, onChange, cellNumber, ...props }) {
  const [checked, setChecked] = useState(!!value)
  const [customNumber, setCustomNumber] = useState(false)
  const [number, setNumber] = useState(value || cellNumber)

  useEffect(() => {
    if (!customNumber) {
      setNumber(cellNumber.substring(0, 13))
    }
  }, [cellNumber])

  useEffect(() => {
    if (!checked) {
      onChange(null)
    }
    else {
      onChange(number)
    }
  }, [checked])

  useEffect(() => {
    if (checked) {
      onChange(number)
    }
  }, [number])

  useEffect(() => {
    if (!customNumber) {
      setNumber(cellNumber.substring(0, 13))
    }
  }, [customNumber])

  return <WhatsAppCheckboxContainer>
    <Row>
      <WhatsAppOptInCheckboxContainer>
        <Checkbox checked={checked} onChange={(event, checked) => { setChecked(checked) }} color="primary" />
      </WhatsAppOptInCheckboxContainer>
      <WhatsAppOptInTextContainer>
        I want to receive communication over WhatsApp {number ? "to" : ""} {number || ""}
        <UseAnotherNumberTextContainer onClick={() => setCustomNumber(!customNumber)}>
          {customNumber ? "Use Cell Number" : "Use a different number"}
        </UseAnotherNumberTextContainer>
      </WhatsAppOptInTextContainer>
      <WhatsAppIconContainer>
        <WhatsAppIcon src={whatsappIcon} />
      </WhatsAppIconContainer>
    </Row>
    {customNumber && <Row style={{ width: "300px", justifyContent: "flex-start" }}><TextInput {...props} label="WhatsApp Number" value={number} onChange={(e) => setNumber(e.target.value.substring(0, 13))} /></Row>}
  </WhatsAppCheckboxContainer>
}

whatsappCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  number: PropTypes.string
}


export default whatsappCheckbox
