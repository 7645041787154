import { StyledLabel, StyledFileInput } from './FileInput.style'
import React from 'react'
import Button from '../../generic/Button'

function FileInput({ input, prompt, onChange, inverted, disabled, accept, ...props }) {
  return <>
    <label htmlFor="FInput"><Button inverted={inverted} disabled={disabled} title={prompt || "Upload"} /></label>
    <StyledFileInput type="file" id="FInput" {...input} {...props}  onChange={onChange} accept={accept} />
  </>
}

export default FileInput
