import React from 'react'
import Text from '../Text'
import Switch from '../Switch'
import Radio from '../Radio'
import { SelectableItemContainer, SelectableItemTitleContainer } from './SelectableItem.style'
import { rightArrow } from '../../../images/itemRow'

interface ISelectableItemProps {
  title: string
  subtext?: string
  value?: string
  selected?: boolean
  control?: 'switch' | 'radio'
  onClick?: (event: any) => void
  onChange?: (event: any) => void
  arrow?: boolean
  outlined?: boolean
  leftIndicatorBarColor: string
}

function SelectableItem({
  title,
  subtext,
  value,
  selected,
  control,
  onClick,
  onChange,
  arrow,
  outlined,
  leftIndicatorBarColor,
}: ISelectableItemProps) {
  return (
    // <ComponentPadder padding="4px 0px">
    <SelectableItemContainer
      leftIndicatorBarColor={leftIndicatorBarColor}
      outlined={outlined}
      selected={selected}
      onClick={onClick || (() => onChange && onChange(!selected))}
    >
      <SelectableItemTitleContainer>
        <Text size={14}>{title}</Text>
        <Text size={10} opacity="0.7">
          {subtext}
        </Text>
      </SelectableItemTitleContainer>
      {value && (
        <Text size={16} bold style={{ paddingRight: '13px' }}>
          {value}
        </Text>
      )}
      {control === 'switch' && <Switch checked={selected} onChange={() => onChange && onChange(!selected)} />}
      {/** @ts-ignore */}
      {control === 'radio' && <Radio checked={selected} onChange={() => onChange && onChange(!selected)} />}
      {arrow && <img src={rightArrow} style={{ margin: 0 }} />}
    </SelectableItemContainer>
    // </ComponentPadder>
  )
}

export default SelectableItem
