import styled from '@emotion/styled'

const RowLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    width: 100%;
  }
`

const ColumnLayoutContainer = styled.div``

interface IColumnSpacerProps {
  width: number | string
}

const ColumnSpacer = styled.span<IColumnSpacerProps>`
  ${(props) => `width: ${typeof props.width == 'string' ? props.width : props.width + 'px'};`}
  display: flex;
  //height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`

interface IRowSpacerProps {
  height: number | string
}

const RowSpacer = styled.div<IRowSpacerProps>`
  ${(props) => `height: ${typeof props.height == 'string' ? props.height : props.height + 'px'};`}
  display: flex;
  //height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`

interface IHorizontalLineProps {
  height: number
  color: string
}

const HorizontalLine = styled.div<IHorizontalLineProps>`
  ${(props) => `height: ${props.height}px;`}
  ${(props) => `background-color: ${props.color};`}
`

interface IComponentPadderProps {
  padding?: string
  borderRadius?: number
  backgroundColor?: string
}

const ComponentPadder = styled.div<IComponentPadderProps>`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: ${(props) => (props.padding ? props.padding : 0)};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 0)}px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'none')};
  & :nth-child(even) {
    //background: #fdd;
  }
  & :nth-child(odd) {
    //background: #dfd;
  }
`

export { RowLayoutContainer, ColumnLayoutContainer, RowSpacer, ColumnSpacer, HorizontalLine, ComponentPadder }
