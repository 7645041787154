import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CircularProgressContainer } from './ActivityIndicator.style'

interface Props {
  white?: boolean
  small?: boolean
}

const ActivityIndicator: React.FC<Props> = ({ white, small }) => {
  return (
    <CircularProgressContainer white={white} small={small}>
      <CircularProgress />
    </CircularProgressContainer>
  )
}

export default ActivityIndicator
