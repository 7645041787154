import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { DatePickerContainer } from './DatePicker.style'
import Text from '../Text'

function MyDatePicker({ label, required, ...props }) {
  return <>
    <Text size={13} padBottom="6px">{label} {required && <Text span primary size={20}>*</Text>}</Text>
    <DatePickerContainer>
    <KeyboardDatePicker 
        {...props} 
        value={props.value ? moment(props.value) : null}
        onChange={v => { console.log(v, moment(v).valueOf()); props.onChange(moment(v).valueOf()) }} 
        onBlur={() => { props.onBlur(); return props.value ? moment(props.value).valueOf() : null }}
        format="DD/MM/yyyy"
        placeholder="DD/MM/YYYY"
        inputVariant="outlined" 
      />
    </DatePickerContainer>
  </>
}

export default MyDatePicker
