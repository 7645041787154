import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  ${props => props.alignVertical && css`
    justify-content: ${props.alignVertical};
  `}

  ${props => props.alignHorizontal && css`
    align-items: ${props.alignHorizontal};
  `}

  ${props => props.wrap && css`
    flex-wrap: wrap;
  `}

  ${props => props.pad && css`
    padding: ${props.pad};
  `}

  ${props => props.padHorizontal && css`
    padding-left: ${props.padHorizontal};
    padding-right: ${props.padHorizontal};
  `}

  ${props => props.padVertical && css`
    padding-top: ${props.padVertical};
    padding-bottom: ${props.padVertical};
  `}

  ${props => props.padLeft && css`
    padding-left: ${props.padLeft};
  `}

  ${props => props.padRight && css`
    padding-right: ${props.padRight};
  `}

  ${props => props.padTop && css`
    padding-top: ${props.padTop};
  `}

  ${props => props.padBottom && css`
    padding-bottom: ${props.padBottom};
  `}
`

export default Row