import React from 'react'
import { css, Global } from '@emotion/core'
import { useTheme as useEmotionTheme } from 'emotion-theming'
import { logo } from './images'

export interface IMeerkatWebColors {
  primary: string
  text: string
  white: string
  dark: string
  grey: string // Light Grey
  grey2a: string // Grey 2
  grey2: string
  grey3: string
  grey4: string
  grey5: string
  grey6: string
  lightgrey: string
  yellow: string // Light Sand
  secondary: string
  darkyellow: string
  red: string // Peach
  alertAmber: string
  alertRed: string
  alertRedBackground: string
  purple: string
  alertYellow: string
  lightGreen: string // Light Green
}

export type IMeerkatWebColor = keyof IMeerkatWebColors

export interface IMeerkatWebTheme {
  fontFamily: string
  fontFamilyBold: string
  colors: IMeerkatWebColors
  logo: any
}

export function useTheme(): IMeerkatWebTheme {
  return useEmotionTheme() as IMeerkatWebTheme
}

const theme = {
  fontFamily: `Work Sans, sans-serif`,
  fontFamilyBold: `Work Sans, sans-serif`,
  // fontFamily: `'EuropaNuova-Regular', Lato, sans-serif`,
  // fontFamilyBold: `'EuropaNuova-Bold', Lato, sans-serif`,
  colors: {
    primary: '#229490',
    text: '#404357',
    white: '#FFFFFF',
    dark: '#404357',
    grey: '#F9F8F8', // Light Grey
    grey2a: '#ACB0B4', // Grey 2
    grey2: '#4F4F4F',
    grey3: '#828282',
    grey4: '#BDBDBD',
    grey5: '#E0E0E0',
    grey6: '#F2F2F2',
    lightgrey: '#ECE8E8',
    yellow: '#EFDCB7', // Light Sand
    secondary: '#EBD18A',
    darkyellow: '#CBAF88',
    red: '#EE8983', // Peach
    alertAmber: '#FF8E3C',
    alertRed: '#EB5757',
    alertRedBackground: '#EB57571F',
    purple: '#331261',
    alertYellow: '#FFDF2E',
    lightGreen: '#E9F4F4', // Light Green
  },
  logo,
}

export const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        font-family: ${theme.fontFamily};
      }

      .MuiTextField-root {
        width: 100% !important;
        max-width: 400px;
        min-width: 150px !important;
      }

      .MuiMenuItem-root {
        font-family: ${theme.fontFamily} !important;
        font-size: 16px !important;
        min-height: 48px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;

        ${device.tablet`
          font-size: 12px !important; 
          min-height: 32px !important;
          padding-left: 12px !important;
          padding-right: 12px !important;
        `}
      }

      .MuiListItemText-primary {
        font-family: ${theme.fontFamily} !important;
        font-size: 16px !important;

        ${device.tablet`
          font-size: 12px !important; 
        `}
      }

      .MuiListItemText-secondary {
        font-family: ${theme.fontFamily} !important;
        font-size: 14px !important;

        ${device.tablet`
          font-size: 10px !important; 
        `}
      }
    `}
  />
)

const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '600px',
  tablet: '820px',
  laptop: '1024px',
  laptopL: '1440px',
  desktopS: '1600px',
  desktop: '2560px',
}

export const device = Object.entries(deviceSizes).reduce(
  (acc: { [key: string]: (style: TemplateStringsArray) => string }, [device, maxWidth]) => ({
    ...acc,
    [device]: (style: TemplateStringsArray) => `
        @media (max-width: ${maxWidth}) {
          ${style};
        }
      `,
  }),
  {}
)

export default theme
