import { API_DEFAULT_TOKEN, APP_DEFAULT_USER_ID } from '../config'
import jwtDecode from 'jwt-decode'

export const isBrowser = () => typeof window !== "undefined"

export const getAccessToken = () => {
  const token = isBrowser() && window.localStorage.getItem("accessToken")
    ? window.localStorage.getItem("accessToken")
    : API_DEFAULT_TOKEN
  
  if (!token || token === "undefined" || token === "null") {
    return API_DEFAULT_TOKEN
  }

  return token
}

export const getAuthAud = () => {
  const aud = isBrowser() && window.localStorage.getItem("auth:aud")
  
  if (!aud || aud === "undefined" || aud === "null") {
    return null
  }

  return aud
}

export const getAuthUuid = () => {
  const token = getAccessToken()
  const decoded = jwtDecode(token)
  return decoded && decoded.uuid
}

export const getAuthUserId = () => {
  const token = getAccessToken()
  const decoded = jwtDecode(token)
  return decoded && decoded.userId
}


export const setAccessToken = token => {
  window.localStorage.setItem("accessToken", token)

  if (!isLoggedIn()) {
    window.localStorage.setItem("auth:defaultToken", token)
  }

  const decoded = jwtDecode(token)
  if (decoded.aud) {
    window.localStorage.setItem("auth:aud", decoded.aud)
  }
}

export const isLoggedIn = () => {
  const token = getAccessToken()
  return isUserToken(token)
}

export const logout = callback => {
  const token = window.localStorage.getItem("auth:defaultToken")
  setAccessToken(token || API_DEFAULT_TOKEN)
  callback()
}
export const isUserToken = (token) => {
  const decoded = jwtDecode(token)
  return decoded && decoded.userId !== APP_DEFAULT_USER_ID
}

// Create Browser Indentifier
