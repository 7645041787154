import React, { useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import ChatContext from '../ChatContext'
import gql from 'graphql-tag'
import ActivityIndicator from '../../components/generic/ActivityIndicator'
import { getAuthAud, getAuthUserId, getAuthUuid, setAccessToken, isBrowser, isLoggedIn } from '../../services/auth'
import { hjIdentify } from '../../lib/tracking'
import firebase from 'gatsby-plugin-firebase'
import { APP_DEFAULT_USER_ID } from '../../config'

// This function sets a field in an object with a name. It supports array and object keys
function setField(fields, name, value) {
  console.log(fields, name, value)
  const tree = name.replace(/\]/g,'').split(/[\.\[]/)
  console.log(tree)
  if (tree.length === 1) {
    fields[tree[0]] = value
    return fields[tree[0]]
  } else {
    if (fields[name]) {
      fields[name] = value
      return fields[name]
    } else {
      if (!fields[tree[0]]) {
        if (parseInt(tree[1]).toString() === tree[1]) {
          const num = parseInt(tree[1])
          if (num === 0) {
            fields[tree[0]] = [{}]
          } else {
            fields[tree[0]] = Array.from(Array(num + 1), () => ({}))
          }
        } else {
          fields[tree[0]] = {}
        }
      } else {
        if (parseInt(tree[1]).toString() === tree[1]) {
          const num = parseInt(tree[1])
          let add = num - fields[tree[0]].length + 1
          while (add > 0) {
            fields[tree[0]].push({})
            add -= 1
          }
        }
      }
      console.log(fields[tree[0]], tree.slice(1).join('.'), value)
      return setField(fields[tree[0]], tree.slice(1).join('.'), value)
    }
  }
}

function isObjectArray(obj) {
  if (obj instanceof Array) {
    return false
  }
  const key = Object.keys(obj)[0]
  return key && key !== 0 && parseInt(key).toString() === key.toString()
}

function combineObjects(current, values) {
  const result = { ...current }
  Object.keys(values).forEach(key => {
    // console.log('Combine', key)
    const value = values[key]
    if (current[key]) {
      // console.log('Combine key exists')
      if (value instanceof Object || value instanceof Array) {
        // console.log('Combine It is an Object')
        let isArray = false
        if (current[key] instanceof Array || isObjectArray(current[key])) {
          isArray = true
        }
        result[key] = { ...current[key] }
        result[key] = combineObjects(result[key], value)

        // console.log('Combine Changes...', current[key], value, result[key])

        if (isArray) {
          // console.log('Combine Is an array ', result, key)
          result[key] = Array.from(Array(Math.max(...Object.keys(result[key])) + 1), (_, index) => result[key][index] || {})
        }
      } else {
        result[key] = value
      }
    } else {
      result[key] = value
    }
  })
  // console.log('Combine Values:', current, values, result)
  return result
}

function valuesToObject(values) {
  let result = {}
  console.log('VALUES=', values)
  Object.keys(values).forEach(key => {
    if (key.includes("[") || key.includes(".")) {
      setField(result, key, values[key])
    } else {
      result[key] = values[key]
    }
  })
  return result
}

const UPDATE_CHAT_STATE = gql`
  mutation UpdateChatState($stepId: String, $values: String) {
    updateChatState(stepId: $stepId, values: $values)
  }
`

const START_CHAT = gql`
  mutation($aud: String, $utmSource: String, $utmMedium: String, $utmCampaign: String, $utmReferredBy: String) {
    startChat(aud: $aud, utmSource: $utmSource, utmMedium: $utmMedium, utmCampaign: $utmCampaign, utmReferredBy: $utmReferredBy)
  }
`

const GET_CHAT_STATE = gql`{
  myChatState {
    chatState
  }
}`


const ChatProvider = ({ client, initialValues, children }) => {
  const [chatState, setChatState] = useState(initialValues || {})
  const [loading, setLoading] = useState(false)
  const [formSubmitDone, setFormSubmitDone] = useState(false)


  let currentChatState = chatState

  useEffect(() => {
    console.log('Resetting Chat Provider ...')
    
    const variables =  {
      aud: getAuthAud()
    }
    if (isBrowser()) {
      variables.utmReferredBy = new URLSearchParams(window.location.search).get("utm_referred_by")
      variables.utmSource = new URLSearchParams(window.location.search).get("utm_source")
      variables.utmCampaign = new URLSearchParams(window.location.search).get("utm_campaign")
      variables.utmMedium = new URLSearchParams(window.location.search).get("utm_medium")
    }
    client.mutate({ mutation: START_CHAT, variables }).then(({ data }) => {
      if (typeof R4 === 'undefined' && !global.R4) {
        setAccessToken(data.startChat);
      }
      setChatState({});
      const userId = getAuthUserId()

      firebase.analytics().setUserProperties({
        aud: getAuthAud(),
        uuid: getAuthUuid(),
        userId
      })

      const hjUserId = userId === APP_DEFAULT_USER_ID ? null : userId
      hjIdentify(hjUserId, {
        uuid: getAuthUuid(),
        aud: getAuthAud()
      })
      // setLoading(false)
      /*client.query({ query: GET_CHAT_STATE }).then(({ data }) => {
        setChatState(JSON.parse((data && data.myChatState && data.myChatState.chatState) || "{}"))
        setLoading(false)
      })*/
    });
  }, [])

  const updateChatState = (stepIds, values) => {
    const newState = valuesToObject(values)
    console.log(values, newState)
    const nextChatState = combineObjects(currentChatState, newState)
    console.log('CHAT STATE: ', chatState, currentChatState, newState, nextChatState)
    setChatState(nextChatState)
    currentChatState = nextChatState
    client.mutate({ mutation: UPDATE_CHAT_STATE, variables: { stepId: stepIds.join(','), values: JSON.stringify(values) } })
    return nextChatState
  }

  const clearChatState = () => {
    setChatState({})
  }

  if (loading) {
    return <ActivityIndicator large="true"/>
  }

  return (
    <ChatContext.Provider value={{ chatState, updateChatState, formSubmitDone, setFormSubmitDone, clearChatState }}>
      {children}
    </ChatContext.Provider>
  )
}

export default withApollo(ChatProvider)
