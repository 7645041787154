import React, { useState } from 'react'
import Radio from '@material-ui/core/Radio';
import { RadioContainer } from './Radio.style';

function MyRadio({ item, value, checked, onChange }) {
  return <RadioContainer>
    <Radio defaultChecked={false} checked={checked || (item && value && (item === value)) || false} onChange={onChange} />
  </RadioContainer>
}

export default MyRadio
