import styled from '@emotion/styled'

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    width: 100%
  }
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
`

export {
  LayoutContainer,
  FieldContainer
}