import i18n from "i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",

    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },

    // have a common namespace used around the full app
    ns: ["common"],
    defaultNS: "common",
    fallbackNS: "common",

    debug: true,

    initImmediate : false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
      useSuspense: false
    },
  })

export default i18n