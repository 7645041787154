import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import { device } from '../../../theme.style'

const TextFieldContainer = styled.div`
  margin-bottom: 4px;
  width: 100%;

  .MuiInputLabel-asterisk {
    color: ${props => props.theme.colors.primary};
    font-size: 20px;
    position: relative; 
    font-weight: 900;
  }

  .MuiFormLabel-root {
    font-family: ${props => props.theme.fontFamily} !important; 
    font-size: 16px;
    line-height: 16px; 
    
    &.Mui-focused {
      color: ${props => props.theme.colors.primary}
    }
  }

  .MuiInputBase-root {
    font-family: ${props => props.theme.fontFamily} !important;
    font-size: 16px;
    line-height: 19px;
    
    &.Mui-focused fieldset {
      border-color: ${props => props.theme.colors.primary}
    }
  }

  .MuiInputLabel-outlined {
    transform matrix(1, 0, 0, 1, 14, 20);
  }

  .MuiOutlinedInput-input {
    padding-left: 14px; !important;
    padding-right: 14px; !important;
    padding-top: 18.5px; !important;
    padding-bottom: 18.5px; !important;

    border: 0;
    height: auto;
  }
`

export {
  TextFieldContainer
}
