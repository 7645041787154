import styled from '@emotion/styled'

const RadioContainer = styled.div`
  .MuiRadio-root {
    padding: 0;
    color: ${props => props.theme.colors.grey5};
  }

  .Mui-checked {
    color: ${props => props.theme.colors.primary} !important;
  }
`

export {
  RadioContainer
}