import styled from '@emotion/styled'
import myTheme from '../../../theme.style'

const switchMaterialStyle = (theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: 0,
  },
  switchBase: {
    padding: 1,
    color: myTheme.colors.primary,
    '&$checked': {
      transform: 'translateX(16px)',
      color: "white",
      '& + $track': {
        backgroundColor: myTheme.colors.primary,
        opacity: 1
      },
    }
  },
  thumb: {
    width: 18,
    height: 18,
    border: `2px solid ${myTheme.colors.primary}`,
    borderRadius: 14,
    boxShadow: 'none'
  },
  track: {
    height: 20,
    borderRadius: 28,
    border: `2px solid ${myTheme.colors.primary}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
  },
  checked: {},
  focusVisible: {}
})

export {
  switchMaterialStyle
}