import React, { useState, useEffect } from 'react'
import Text from '../Text'
import { ColumnSpacer } from '../../generic/Layouts'
import { SelectedButton, UnselectedButton, SelectorContainer } from './Selector.style'

interface ISelectorValue<SelectType> {
  label: string
  value: SelectType
}

interface ISelectorProps<SelectType> {
  label: string
  required?: boolean
  value: SelectType
  defaultValue: SelectType
  onChange?: (newSelectorValue: SelectType) => void
  options: ISelectorValue<SelectType>[]
}

//Single select
function Selector<SelectType>({ onChange, defaultValue, value, options, label, required }: ISelectorProps<SelectType>) {
  const [selectorValue, setSelectorValueAux] = useState<SelectType>(value)
  useEffect(() => {
    if (!value) {
      setSelectorValue(defaultValue || options[0].value)
    }
  }, [])

  const setSelectorValue = (newSelector: SelectType) => {
    onChange && onChange(newSelector)
    setSelectorValueAux(newSelector)
  }

  const getComponent = (selectorValueArg: ISelectorValue<SelectType>) => {
    const Component = selectorValue === selectorValueArg.value ? SelectedButton : UnselectedButton
    return (
      <>
        <Component
          onClick={() => {
            setSelectorValue(selectorValueArg.value)
          }}
          size="large"
        >
          <Text size={16} bold={selectorValue === selectorValueArg.value}>
            {selectorValueArg.label}
          </Text>
        </Component>
      </>
    )
  }

  return (
    <>
      {label && (
        <Text padBottom="8px" size={13}>
          {label}{' '}
          {required && (
            <Text span primary size={20}>
              *
            </Text>
          )}
        </Text>
      )}
      <SelectorContainer>
        {options.map((selectorValueTemp, index) => {
          return (
            <>
              {index == 0 ? <></> : <ColumnSpacer width={8} />}
              {getComponent(selectorValueTemp)}
            </>
          )
        })}
      </SelectorContainer>
    </>
  )
}

export { ISelectorValue, ISelectorProps }

export default Selector
