import React from 'react'
import { UploadedFileContainer, DeleteIcon } from './UploadedFile.style'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import ActivityIndicator from '../ActivityIndicator'
import { trash } from '../../../images'
import Text from '../Text'

const GET_FILE_INFO = gql`query ($id: String!) {
  file(id: $id) {
    id
    name
  }
}`

function UploadedFile({ id, prompt, onDelete }) {
  const { data, loading, error } = useQuery(GET_FILE_INFO, { variables: { id }})

  if (loading) {
    return <ActivityIndicator />
  }

  return <>
    <Text size={12}>{prompt}</Text>
    <UploadedFileContainer>
      <Text size={13}>{data.file.name}</Text>
      <DeleteIcon src={trash} onClick={onDelete} />
    </UploadedFileContainer>
  </>
}

export default UploadedFile
