export const API_URL = process.env.GATSBY_API_URL
export const API_AUTH_ID = process.env.GATSBY_API_AUTH_ID
export const API_DEFAULT_TOKEN = process.env.GATSBY_API_DEFAULT_TOKEN
export const APP_VERSION = process.env.GATSBY_APP_VERSION
export const APP_DEFAULT_USER_ID = process.env.GATSBY_APP_DEFAULT_USER_ID
export const LINKS_API_URL = process.env.GATSBY_LINKS_API_URL
export const API_SUBSCRIPTION_URL = process.env.GATSBY_API_SUBSCRIPTION_URL
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
export const WORDPRESS_BLOG_URL = process.env.GATSBY_WORDPRESS_BLOG_URL
export const WEBSITE_ADDRESS = process.env.GATSBY_WEBSITE_ADDRESS
export const PORTAL_URL = process.env.GATSBY_PORTAL_URL
export const MY_ADDRESS = process.env.GATSBY_MY_ADDRESS
export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID