import React from 'react'
import styled from '@emotion/styled'
import { Button, ButtonProps } from '@material-ui/core'
import theme from '../../../theme.style'

function SelectedButton(props: ButtonProps) {
  const ButtonTemp = styled(Button)`
    &.MuiButton-contained {
      background-color: ${theme.colors.lightGreen};
      border: 1px solid transparent;
      width: 100%;
      height: 48px;
      text-transform: none;
      border-radius: 4px;
    }
  `

  return <ButtonTemp variant="contained" disableElevation {...props} />
}

function UnselectedButton(props: ButtonProps) {
  const ButtonTemp = styled(Button)`
    &.MuiButton-contained {
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.grey5};
      width: 100%;
      height: 48px;
      text-transform: none;
      border-radius: 4px;
    }
  `

  return <ButtonTemp variant="contained" disableElevation {...props} />
}

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
`

interface IRowSpacerProps {
  width: number
}

const RowSpacer = styled.div<IRowSpacerProps>`
  display: flex;
  ${(props) => `width: ${props.width}px;`}
  flexgrow: 0;
  flex-shrink: 0;
`

export { SelectedButton, UnselectedButton, SelectorContainer, RowSpacer }
