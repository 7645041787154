import { createStore  } from 'redux'
import reducer from '../reducers'

function configureStore(initialState) {
  const windowGlobal = typeof window !== 'undefined' && window

  const devtools =
  process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

  return createStore(reducer, initialState, devtools)
}

const store = (initialState = {}) => configureStore(initialState)

export default store
