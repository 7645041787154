import styled from '@emotion/styled'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const WhatsAppCheckboxContainer = styled.div`
  padding-top: 8px;

  .MuiTypography-body1 {
    font-family: ${(props) => props.theme.fontFamily};
  }

  .MuiCheckbox-colorPrimary {
    color: ${(props) => props.theme.colors.primary} !important;
    
    .Mui-checked {
      color: ${(props) => props.theme.colors.primary} !important;
    }
  }

  .MuiIconButton-colorPrimary {
    color: ${(props) => props.theme.colors.primary};
  }

  .parent {
    border: 1px solid black;
    margin: 1rem;
    padding: 1rem 1rem;
    text-align: left;
    display: contents;

  }
  .child {
    display: table-cell;
    vertical-align: middle;
  }
  img {
    vertical-align: middle;
    margin-bottom: 0rem;
    width: 70px;
    margin-top: 2px;
  }
 
  i {
    color:grey
  }
`

const WhatsAppIcon = styled.img``

const WhatsAppIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: 8px;
  margin-right: 8px;
`

const WhatsAppOptInCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  padding: 0px;
  margin: 0px;
  margin-left: -1px;

  .MuiButtonBase-root {
    padding: 0px;
  }
`

const WhatsAppOptInTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  margin-left: 12px;
  margin-right: 16px;
  font-family: Work Sans;
  justify-content: center;
  flex-grow: 1;
`

const UseAnotherNumberTextContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  font-weight: 700;
  color: ${props => props.theme.colors.grey3};
  cursor: pointer;
`

export { 
  WhatsAppCheckboxContainer,
  Row,
  WhatsAppIcon,
  WhatsAppIconContainer,
  WhatsAppOptInCheckboxContainer,
  WhatsAppOptInTextContainer,
  UseAnotherNumberTextContainer 
}
