import React, { useState } from 'react'
import FileInput from '../FileInput'
import axios from 'axios'
import { FileUploadContainer } from './FileUpload.style'
import CameraInput from '../CameraInput'
import UploadedFile from '../UploadedFile'
import { API_URL } from '../../../config'
import ActivityIndicator from '../ActivityIndicator'
import { getAccessToken } from '../../../services/auth'

const isMobileDevice = () => typeof navigator !== "undefined" && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

function FileUpload({ onChange, prompt, value, renderFileInput, inverted, disabled, accept, capture }) {
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [fileName, setFileName] = useState

  const  uploadDocumentRequest = (e, v) => {
    if (e.target.files[0]) {
      let data = new FormData();
      const { name } = e.target.files[0]
      data.append('name', name);
      data.append('file', e.target.files[0])

      const config = {
        method: "POST",
        headers: { 'Authorization': `JWT ${getAccessToken()}` },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgress(percentCompleted)
        }
      }

      setUploading(true)
      axios.put(`${API_URL}upload`,data, config)
        .then(result => {
          setUploading(false)
          console.log('file upload result ', result)
          onChange && onChange(result.data[0].id)
        })
        .catch(console.error)
    }
  }

  if (value) {
    return <UploadedFile id={value} prompt={prompt} onDelete={() => onChange(null)}/>
  }

  if (renderFileInput) {
    const MyFileInput = renderFileInput
    return <MyFileInput onChange={uploadDocumentRequest} progress={progress} prompt={prompt} accept={accept} />
  } else {

    return (
      <FileUploadContainer>
        {!uploading && <FileInput onChange={uploadDocumentRequest} prompt={prompt} inverted={inverted} disabled={disabled} accept={accept} capture={capture} />}
        {/*!uploading && isMobileDevice() && <CameraInput onChange={uploadDocumentRequest} />*/}
        {/*<BarFillStripes progress={progress}/>*/}
        {uploading && <ActivityIndicator />}
      </FileUploadContainer>
    )
  }
}

// FileUpload.propTypes = {
// }

export default FileUpload
